import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../components/seo';
import IndustryPrivacySelector from '../components/sections/privacy/industry-privacy-selector';
import PrivacyButtons from '../components/sections/privacy/privacy-buttons';
import './accelerate-reopening-of-hospitality-industry.css';

const PrivacyNightclubsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      privacyHero: file(relativePath: { eq: "hero-images/privacy-hero.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      privacyFeature: file(relativePath: { eq: "hero-images/privacy_feature.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="release-content">
      <SEO title="COVID-19 Technology Could Accelerate Reopening of Hospitality Industry - Patronscan" />
      <h1 className="release-title">
        COVID-19 Technology Could Accelerate Reopening of Hospitality Industry
      </h1>
      <h2 className="release-sub-title">
        Contact tracing critical to transitioning industry back from take-out to dine-in
      </h2>
      <p className="release-text">
        May 19, 2020 (Calgary, Canada) – Patronscan, a leading manufacturer of I.D. scanning
        technology, has developed a contact tracing guest registry that can help the hospitality,
        sports and entertainment industry transition from an era of stay-at-home orders to open for
        business.
      </p>
      <p className="release-text">
        “To restore economic growth and jobs, public healthcare experts are wrestling with how best
        to transition bars and restaurants from take-out to dine-in and how to reintroduce the
        public to gathering safely in public spaces as stay-at-home orders are relaxed,” said
        Alberio Bathory-Frota, CEO of Patronscan, a worldwide I.D. scanning firm. “To assure
        customers that a place of business has social distancing protocols in place and that they
        will be notified of any potential health risk, Patronscan has developed an electronic guest
        registry that measures occupancy levels and makes contact tracing faster and safer. Guest
        registries are a powerful tool for businesses seeking to rebuild their customer base and to
        get workers back on payroll.”
      </p>
      <p className="release-text">
        Underscoring the of getting people back to work, Bathory-Frota said, “Government agencies
        throughout the world are releasing COVID-19 guidelines so that restaurants, bars, movie
        theaters, sporting events and other public venues can open safely. Their challenge is how to
        restore economic activity and jobs without exposing customers and employees to potential
        health risks. An increasing number of public healthcare experts believe{' '}
        <a href="https://www.npr.org/sections/health-shots/2020/04/14/833726999/how-contact-tracing-can-help-fight-coronavirus">
          contact tracing
        </a>{' '}
        and guest registries are part of the solution.”
      </p>
      <p className="release-text">
        <a href="https://www.newsroom.co.nz/2020/03/21/1094409/covid-19-mandatory-guest-register-for-hospitality-venues">
          New Zealand
        </a>{' '}
        has adopted a national policy of requiring its hospitality industry to register all guests,
        a protocol to control occupancy and to reduce the spread of coronavirus through contact
        tracing. If a customer or employee contracts COVID-19, healthcare providers use the
        information to notify employees and customers of the need to be tested and/or seek medical
        care. The States of{' '}
        <a href="https://seattle.eater.com/2020/5/12/21255614/washington-state-requiring-contact-tracing-at-restaurants-logging-phones-emails">
          Washington
        </a>
        ,{' '}
        <a href="https://www.maine.gov/decd/sites/maine.gov.decd/files/inline-files/COVID Checklist for ME Phase 1 Restaurants.pdf">
          Maine
        </a>{' '}
        and{' '}
        <a href="https://www.cnn.com/us/live-news/us-coronavirus-update-05-11-20/h_424a0e91313ac719e9094c49d70db370">
          Rhode Island
        </a>{' '}
        have similar policies, both mandatory and voluntary, as do the cities of{' '}
        <a href="https://austin.eater.com/2020/5/8/21252313/austin-restaurant-reopen-track-dine-in-customers-coronavirus">
          Austin, Texas
        </a>{' '}
        and{' '}
        <a href="https://www.wdsu.com/article/here-are-the-guidelines-for-new-orleans-restaurants-reopening/32452626">
          New Orleans, Louisiana
        </a>
        .
      </p>
      <p className="release-text">
        Patronscan can work with healthcare agencies and various business industries to customize
        software features to meet or exceed established social distancing protocols and to comply
        with privacy laws. The user-friendly technology allows businesses to quickly process,
        authenticate and store customer information in compliance with local privacy laws. The
        registry ensures that data is accurate, secured off-site, protected and then permanently
        deleted between 3-4 weeks, or as determined by government guidelines. The software uses iOS,
        Android devices or Patronscan’s I.D. scanner kiosks to scan/swipe government issued I.D.s.
        This is a safer alternative to registering guests on a notepad. Data is not shared with
        third parties or stored with government agencies. It is not designed to deny patrons
        entrance to an establishment, nor is it an app that tracks a patron’s internet activities.
        The registry will be offered free to non-profit organizations and Patronscan’s existing
        customers.
      </p>
      <p className="release-text text-center">###</p>
      <p className="release-sub-text">
        Since 2005, Patronscan, a Canadian firm, has equipped businesses with technology that
        provides greater security for their guests, staff and property. Patronscan protects over 600
        venues, spanning 200 cities worldwide and has scanned over 100 million government issued
        identifications (I.D.s). Patronscan is a leader in bar and nightclub technology that
        identifies underaged drinkers and protects businesses and customers by identifying patrons
        with a history of property damage, violent behavior and sexual assault.
      </p>
      <p className="release-sub-text">
        Contact: Marko Mlikotin
        <br />
        River City Communications
        <br />
        <a href="mailto:marko@rivercitycomm.com">marko@rivercitycomm.com</a>
        <br />
        <a href="tel:+19167997574">916.799.7574</a> (PST)
      </p>
    </div>
  );
};

export default PrivacyNightclubsPage;
